import React from "react";
import { BasicTable } from "../../../SharedComponents/Table/BasicTable";
import { createColumns } from "./Columns";

const Students = ({ data }) => {
  return (
    <>
      <BasicTable
        data={data}
        createColumns={() => createColumns()}
        isSelection={false}
      />
    </>
  );
};
export default Students;
