import React, { useState, useEffect } from "react";
import {
  Header,
  Image,
  Segment,
  Icon,
  Divider,
  Button,
} from "semantic-ui-react";
import reportsImage from "../../media/Images/reports.jpg";
import Students from "./Students";
import Chart from "./Chart";
import $ from "jquery";
import styles from "./StudentReport.module.scss";

const Reports = () => {
  const [page, setPage] = useState("Chart");
  const [loading, setLoading] = useState(true);
  const [activeStudents, setActiveStudents] = useState("");

  useEffect(() => {
    $.ajax({
      url: `/api/reports/active-students`, //TODO: update request URL
      type: "GET",
      beforeSend: function (xhr) {
        //Include the bearer token in header
        xhr.setRequestHeader(
          "Authorization",
          "Bearer " + sessionStorage.getItem("access_token")
        );
      },
      success: (result) => {
        setActiveStudents(result);
        setLoading(false);
        return;
      },
      error: (error) => {
        alert(
          "Unable to load active & none active students. Please try your request again"
        );
        return;
      },
    });
  }, []);
  return (
    <>
      <div id="headerPartPayment">
        <Header id="HeaderPayment" as="h2" block={true} value={"Reports"}>
          <Image size="large" circular src={reportsImage} /> Students Report
        </Header>
      </div>
      <Divider horizontal />

      {loading ? (
        <Segment loading>Loading...</Segment>
      ) : (
        <>
          <div className={styles.page}>
            <Button
              id="submit_view_subs_payments"
              floated="left"
              size="medium"
              color="red"
              onClick={() => setPage("Chart")}
              className={styles.button}
            >
              Summary
            </Button>
            <Button
              id="submit_view_subs_payments"
              floated="left"
              size="mini"
              color="red"
              onClick={() => setPage("ActiveStudents")}
              className={styles.button}
            >
              Active Subscriptions
            </Button>
            <Button
              id="submit_view_subs_payments"
              floated="left"
              size="mini"
              color="red"
              className={styles.button}
              onClick={() =>
                setPage("students_with_expired_subscriptions_current_month")
              }
            >
              Expired Subscriptions ({activeStudents["summary"]["currnt_month"]}
              )
            </Button>
            <Divider horizontal />
            <Button
              id="submit_view_subs_payments"
              floated="left"
              size="mini"
              color="red"
              className={styles.button}
              onClick={() =>
                setPage("students_with_expired_subscriptions_last_3_month")
              }
            >
              Expired Subscriptions (
              {activeStudents["summary"]["last2nd_month"]} to{" "}
              {activeStudents["summary"]["last_month"]})
            </Button>
            <Button
              id="submit_view_subs_payments"
              floated="left"
              size="mini"
              className={styles.button}
              color="red"
              onClick={() =>
                setPage("students_with_rest_expired_subscriptions")
              }
            >
              Expired Subscriptions (Rest)
            </Button>
            <Button
              id="submit_view_subs_payments"
              floated="left"
              size="mini"
              className={styles.button}
              color="red"
              onClick={() => setPage("students_with_no_subscriptions")}
            >
              No Subscriptions
            </Button>
          </div>
          <Divider horizontal />

          <div className={styles.page}>
            {page === "Chart" && <Chart Summary={activeStudents["summary"]} />}
            {page === "ActiveStudents" && (
              <Students
                data={activeStudents["students_with_active_subscription"]}
              />
            )}
            {page === "students_with_expired_subscriptions_current_month" && (
              <Students
                data={
                  activeStudents[
                    "students_with_expired_subscriptions_current_month"
                  ]
                }
              />
            )}
            {page === "students_with_expired_subscriptions_last_3_month" && (
              <Students
                data={
                  activeStudents[
                    "students_with_expired_subscriptions_last_3_month"
                  ]
                }
              />
            )}
            {page === "students_with_rest_expired_subscriptions" && (
              <Students
                data={
                  activeStudents["students_with_rest_expired_subscriptions"]
                }
              />
            )}
            {page === "students_with_no_subscriptions" && (
              <Students
                data={activeStudents["students_with_no_subscriptions"]}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Reports;
